import BoardViewForm from '../components/BoardViewForm';


const BoardView = () => {
  return (
      <BoardViewForm />
);
};

export default BoardView;
