import BoardEditForm from '../components/BoardEditForm';


const BoardEditPage = () => {
  return (
      <BoardEditForm />
  );
};

export default BoardEditPage;
