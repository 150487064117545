import BoardWriteForm from '../components/BoardWriteForm';


const BoardWrite = () => {
    return (
        <BoardWriteForm />
    );
};

export default BoardWrite;
